<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="category.id">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить категорию</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editCategoryForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model="category.name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <div
              v-if="category.icon && typeof category.icon == 'string'"
              class="image-wrapper"
            >
              <img class="image" :src="category.icon" alt="Icon" />
              <span class="remove-icon" @click="category.icon = null">
                <md-icon>close</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </span>
            </div>

            <ValidationProvider
              v-else
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Основное изображение</label>
                <md-file
                  v-model="iconName"
                  accept="image/*"
                  @md-change="
                    category.icon = $event[0];
                    reset();
                  "
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Оценочный вес</label>
                <md-input v-model="category.cover" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Порядок</label>
                <md-input
                  v-model="category.order"
                  type="number"
                  min="0"
                  @focus="reset"
                  @change="validateOrder"
                />
              </md-field>
            </ValidationProvider>

            <Treeselect
              v-model="category.parent_id"
              :options="categories"
              value-consists-of="LEAF_PRIORITY"
              placeholder="Родительская категория"
            />

            <md-checkbox v-model="category.is_active">Видимость</md-checkbox>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="editCategory">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
  },

  data() {
    return {
      category: {},
      iconName: "", // hack for vee-validate
    };
  },

  computed: {
    ...mapState({
      initCategory: (state) => state.categories.category,
    }),

    ...mapGetters({
      categories: "additionalData/reworkedCategories",
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getAdditionalData();
    let success = await this.getCategory(+this.$route.params.id);
    if (success) {
      this.$set(this.category, "id", this.initCategory.id);
      this.$set(this.category, "name", this.initCategory.name);
      this.$set(this.category, "icon", this.initCategory.icon);
      this.$set(this.category, "cover", this.initCategory.cover);
      this.$set(this.category, "is_active", !!this.initCategory.is_active);
      this.$set(this.category, "order", this.initCategory.order);
      this.$set(this.category, "parent_id", this.initCategory.parent_id);
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("additionalData", ["getAdditionalData"]),
    ...mapActions("categories", ["updateCategory", "getCategory"]),

    async editCategory() {
      const isValid = await this.$refs.editCategoryForm.validate();
      if (!isValid) {
        return;
      }

      let category = new FormData();

      category.append("name", this.category.name);
      category.append("cover", this.category.cover);
      category.append("is_active", +this.category.is_active);
      category.append("order", this.category.order);

      if (this.category.parent_id) {
        category.append("parent_id", this.category.parent_id);
      }

      if (this.category.icon && typeof this.category.icon !== "string") {
        category.append("icon", this.category.icon);
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateCategory({
        id: this.initCategory.id,
        category,
      });
      if (success) {
        this.$router.push("/categories");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    validateOrder(event) {
      if (event.target.value < 0) {
        this.category.order = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}

.image-wrapper {
  position: relative;
  max-width: 150px;

  &:hover {
    &::before,
    .remove-icon {
      opacity: 1;
      visibility: visible;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
  }

  .remove-icon {
    position: absolute;
    right: 3px;
    top: 3px;
    width: 24px;
    height: 24px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    cursor: pointer;

    & > * {
      color: red;
    }
  }
}
</style>
